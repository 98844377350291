import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, Select, MenuItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import RouterOutlinedIcon from '@mui/icons-material/RouterOutlined';

import { colors } from '../constants'; 
import CrossFadeImage from '../CrossFadeImage'; // Adjust this path to point to the correct location

const isRedFlag = (item, field) => {
  const flag =

    (field === 'hostname' && item.hostname === null) ||
    (field === 'ip_address' && item.ip_address === '0.0.0.0') ||
    (field === 'static_content' && item.static_content) ||
    (field === 'screen_on' && !item.screen_on) ||
    (field === 'screen_scaling' && (item.screen_scaling < 1 || item.screen_scaling > 2)) ||
    (field === 'screen_resolution' && (item.screen_resolution !== '2160 x 3840' && item.screen_resolution !== '1080 x 1920'));
  return flag;
};

const RedFlag = ({ condition, children }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: condition ? 'bold' : 'normal',
        color: condition ? 'red' : 'lightgrey',
      }}
    >
      {condition && <WarningIcon color="error" fontSize="small" sx={{ marginRight: 0.5 }} />}
      {children}
    </Typography>
  );
};

const getKey = item => item.images?.[0].name ?? item.hostname;

const DataGridV2 = ({ data }) => {
  return (
    /*
    <Grid container gap={4} sx={{ minHeight: '100vh', paddingBottom: 3, maxWidth: 'none', margin: 'auto' }} >
      {data.map((item, index) => {
        return (
          <div key={getKey(item)} style={{ color: 'white', backgroundColor: colors.passDark, padding:15, borderRadius:10, display: 'flex', flexDirection: 'column', gap: 10, width: 440 }}>
            <div style={{ display: 'flex', gap:15 }}>
              <div style={{ height: 205, width: 109 }}>
                <img src={item.images?.[0].url} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius:7 }}/>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap:10, flex:1 }}>
    */
              <Grid container gap={4} sx={{ minHeight: '100vh', paddingBottom: 3, maxWidth: 'none', margin: 'auto' }} >
              {data.map((item, index) => {
                return (
                  <div key={getKey(item)} style={{ color: 'white', backgroundColor: colors.passDark, padding:15, borderRadius:10, display: 'flex', flexDirection: 'column', gap: 10, width: 440 }}>
                    <div style={{ display: 'flex', gap:15 }}>
                      <div style={{ height: 205, width: 109 }}>
                        <CrossFadeImage src={item.images?.[0].url} alt="" transition={3} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius:7 }}/>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap:10, flex:1 }}>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 18, fontWeight: 600}}>
                      {item.store}
                    </span>
                    <span style={{ fontSize: 14 }}>
                      {item.unique_device_id}
                    </span>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding:10, paddingLeft: 15, backgroundColor: colors.dark, borderRadius: 7, gap: 12, flex:1 }}>
                  <span style={{ fontSize: 14, fontWeight: 600 }}>Kiosk display</span>
                  <div style={{ display: 'flex', gap: 15 }}>
                    <div><DisplaySettingsOutlinedIcon color='white' fontSize='large' /></div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap:16, flex: 1}}>
                      <div style={{ display: 'flex', flexDirection: 'column', fontSize: 13, fontWeight: 600}}>
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          <span style={{ display:'inline-block', width:'48%' }}>Static:</span>
                          <span style={{ color: isRedFlag(item, 'static_content') ? colors.red : colors.green }}>{item.static_content && 'Yes' || 'No'}</span>
                        </div>
                        <div style={{ fontSize: 13, fontWeight: 600 }} >
                          <span style={{ display:'inline-block', width:'48%'  }}>Screen On:</span>
                          <span style={{ color: isRedFlag(item, 'screen_on') ? colors.red : colors.green }}>{item.screen_on && 'Yes' || 'No'}</span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <div style={{ fontSize: 13, fontWeight: 600}} >
                          <span style={{ display:'inline-block', width:'48%'  }}>Scaling:</span>
                          <span style={{ color: isRedFlag(item, 'screen_scaling') ? colors.red : colors.green }}>{item.screen_scaling}</span>
                        </div>
                        <div style={{ fontSize: 13, fontWeight: 600}}>
                          <span style={{ display:'inline-block', width:'48%'  }}>Resolution:</span>
                          <span style={{ color: isRedFlag(item, 'screen_resolution') ? colors.red : colors.green }}>{item.screen_resolution}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: 15 }}>
              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: colors.dark, borderRadius: 7, padding:10, paddingLeft: 15, gap:12 }}>
                <span style={{ fontSize: 14, fontWeight: 600 }}>Touch count</span>
                <div style={{ display: 'flex', gap: 15 }}>
                  <div><TouchAppOutlinedIcon color='white' fontSize='large' /></div>
                  <span style={{ color: isRedFlag(item, 'touchcount') ? colors.red : colors.green, fontSize: 25 }}>{item.touchcount}</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: colors.dark, borderRadius: 7, padding:10, paddingLeft: 15, gap:12, flex:1 }}>
                <span style={{ fontSize: 14, fontWeight: 600 }}>Kiosk network</span>
                <div style={{ display: 'flex', gap: 15, flex: 1 }}>
                  <div><RouterOutlinedIcon color='white' fontSize='large' /></div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1}}>
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 13, fontWeight: 600}}>
                      <div>
                        <span style={{ display:'inline-block', width:'48%'  }}>IP address:</span>
                        <span style={{ color: isRedFlag(item, 'ip_address') ? colors.red : colors.green }}>{item.ip_address}</span>
                      </div>
                      <div>
                        <span style={{ display:'inline-block', width:'48%'  }}>Hostname:</span>
                        <span style={{ color: isRedFlag(item, 'hostname') ? colors.red : colors.green }}>{item.hostname.startsWith('SIG-') ? item.hostname.slice(4) : item.hostname}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Grid>
  );
};

const DataGrid = ({ data }) => {
  const items = [];
  const [selectedItems, setSelectedItems] = useState(items);

  const handleChange = (index, event) => {
    console.log(index)
    console.log(event.target.value)
    // const newSelectedItems = [...selectedItems];
    // newSelectedItems[index] = event.target.value;
    // setSelectedItems(newSelectedItems);
  };

  return (
    <Grid container spacing={3} sx={{ minHeight: '100vh', paddingBottom: 3, maxWidth: 'none' }} >
      {data.map((item, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <Card
              elevation={3}
              sx={{
                width: 280,
                height: 200,
                position: 'relative',
                cursor: 'pointer',
                backgroundColor: 'rgb(28, 26, 62)',
                boxShadow: 'none',
                borderRadius: 1,
                '&:hover': {
                  transform: 'scale(1.04) rotate(1deg)',
                },
              }}
            >
              <div
                sx={{
                  position: 'absolute',
                  width: '100px',
                  height: '100px',
                  top: '-25px',
                  left: '-25px',
                  borderRadius: '50%',
                  background: 'radial-gradient(#b0e633, #53ef7d)',
                  animation: 'move-up6 2s ease-in infinite alternate-reverse',
                }}
              ></div>
              <div
                sx={{
                  position: 'absolute',
                  width: '100px',
                  height: '100px',
                  bottom: '-25px',
                  right: '-25px',
                  borderRadius: '50%',
                  background: 'radial-gradient(#b0e633, #53ef7d)',
                  animation: 'move-down1 2s ease-in infinite alternate-reverse',
                }}
              ></div>
              <CardContent
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255,255,255,.05)',
                  boxShadow: '0 0 10px rgba(0,0,0,0.25)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: 2,
                }}
              >
                <div style={{ display: 'flex'}}>
                  <img />
                  <div>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontWeight: 'bold', color: 'lightgrey', fontSize: '1.2rem' }}
                    >
                      {item.store}
                    </Typography>
                  </div>
                </div>
                <div>
                  row wrapper 2
                </div>

                <RedFlag condition={isRedFlag(item, 'hostname')}>Hostname: {item.hostname}</RedFlag>
                <RedFlag condition={isRedFlag(item, 'ip_address')}>IP Address: {item.ip_address}</RedFlag>
                <RedFlag condition={isRedFlag(item, 'static_content')}>Static Content: {item.static_content ? 'Yes' : 'No'}</RedFlag>
                <RedFlag condition={isRedFlag(item, 'screen_on')}>Screen On: {item.screen_on ? 'Yes' : 'No'}</RedFlag>
                <RedFlag condition={isRedFlag(item, 'screen_scaling')}>Screen Scaling: {item.screen_scaling}</RedFlag>
                <RedFlag condition={isRedFlag(item, 'screen_resolution')}>Screen Resolution: {item.screen_resolution}</RedFlag>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: 'lightgrey', fontSize: '1.2rem' }}
                >
                  Total touch count: {item.touchcount}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: 'lightgrey', fontSize: '1.2rem' }}
                >
                  <Select
                    value={selectedItems[index] || "1"}
                    onChange={(event) => handleChange(index, event)}
                  >
                    <MenuItem value={"lastweek"}>Last Week</MenuItem>
                    <MenuItem value={"lastmonth"}>Last Month</MenuItem>
                  </Select>

                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DataGridV2;