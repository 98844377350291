import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';

const CrossFadeImageContainer = styled('div')({
  position: "relative",
  height: "100%",
  width: "100%",
});

const CrossFadeImageElement = styled('img')(({ transition, isVisible }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "7px",
  transition: `opacity ${transition}s linear`,
  opacity: isVisible ? 1 : 0,
}));

const CrossFadeImage = ({ src, alt, transition = 1, ...rest }) => {
  const [topSrc, setTopSrc] = useState(src);
  const [bottomSrc, setBottomSrc] = useState(src);

  useEffect(() => {
    const timer = setTimeout(() => setBottomSrc(src), transition * 1000);
    setTopSrc(src);
    return () => clearTimeout(timer);
  }, [src]);

  return (
    <CrossFadeImageContainer {...rest}>
      <CrossFadeImageElement src={topSrc} alt={alt} transition={transition} isVisible={true} />
      <CrossFadeImageElement src={bottomSrc} alt={alt} transition={transition} isVisible={topSrc !== bottomSrc} />
    </CrossFadeImageContainer>
  );
};

export default CrossFadeImage;
