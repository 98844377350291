import React, { useState, useEffect } from 'react';
import { fetchData, fetchCount, fetchImages } from './api';
import DataGrid from './components/Grid';
import { CssBaseline, Container } from '@mui/material';
import moment from 'moment';
import './App.css'; // Import the CSS file

const App = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const isRedFlag = (item) => {
      return (
        item.static_content ||
        !item.screen_on ||
        (item.screen_resolution !== '2160x3840' && item.screen_resolution !== '1080x1920')
      );
    };

    const getData = async () => {
      const imageMap = await fetchImages();
      const result = await fetchData();

      const startDate = moment().subtract(1, 'months').format('DD-MM-yyyy');
      const endDate = moment().format('DD-MM-yyyy');

      console.log("start date: "+startDate.toString())
      console.log("end date: "+endDate.toString())

      const countData = await fetchCount(startDate, endDate);
      const countMap = new Map(countData.map(x => [x.unique_device_id, x.count]));
      for (let data of result) {
        data.touchcount = countMap.get(data.unique_device_id);
        data.images = imageMap.get(data.hostname);
      }

      if (Array.isArray(result)) {
        const sortedData = result.sort((a, b) => (isRedFlag(b) - isRedFlag(a)));
        setData(sortedData);
      } else {
        console.error('API response is not an array:', result);
      }
    };
    getData();

    // Set up interval to fetch data every 5 seconds
    const interval = setInterval(getData, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  
  }, []);


  return (
    <div className="App">
      <CssBaseline />
      <Container maxWidth="none" sx={{ padding: '1rem' }}>
        <DataGrid data={data} />
      </Container>
    </div>
  );
};

export default App;
