import axios from 'axios';
import moment from 'moment';

const API_URL = 'https://asignmonitor.nl'; // Replace this with your Strapi URL
const BASE_IMAGES_URL = 'https://ftp.asignmonitor.nl/screenshots/clients/studioanneloes';

const dateFormat = 'DD-MMM-YYYY_HH-mm-ss';
const splitter = '_';

const getMetaDataFromImageName = name => {
  const parts = name.split(splitter);

  return {
    hostname: parts[1],
    date: moment([parts[2], parts[3].replace('.jpg', '')].join(splitter), dateFormat),
    name,
    url: `${BASE_IMAGES_URL}/images/${name.replace(/^.*\/([^/]+)$/, '$1')}` // Update the URL concatenation here
  };
}



const createImageMap = data => {
  const map = new Map();
  const imageKeys = Object.keys(data).filter(item => data[item].startsWith(`${BASE_IMAGES_URL}/images/`));
  
  for (const key of imageKeys) {
    const imageName = data[key];
    const meta = getMetaDataFromImageName(imageName);
    const images = map.get(meta.hostname);
    if (images) {
      images.push(meta);
    } else {
      map.set(meta.hostname, [meta]);
    }
  }

  for (const key of map.keys()) {
    map.set(key, map.get(key).sort((a, b) => b.date.toDate() - a.date.toDate()));
  }

  console.log(map); // Log the map to the console
  return map;
};




export const fetchData = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/kioskstatus/latest`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchCount = async (startdate, enddate) => {
  try {
    let queryString = "";
    if (startdate && enddate) {
      queryString = `?startdate=${startdate}&enddate=${enddate}`;
    } else if (startdate && !enddate) {
      queryString = `?startdate=${startdate}`;
    }

    const response = await axios.get(`${API_URL}/api/useractivity/touchcount${queryString}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchImages = async () => {
  try {
    const response = await axios.get(`${BASE_IMAGES_URL}/get_images_dash.php`);
    const imageMap = createImageMap(response.data);
    console.log(imageMap); // Log the image map to the console

    for (const imageName of Object.values(response.data).filter(item => item.startsWith('screenshot_SIG'))) {
      const meta = getMetaDataFromImageName(imageName);
      console.log(meta); // Log the metadata to the console
      // ...
    }

    return imageMap;
  } catch (error) {
    console.error('Error fetching images:', error);
  }
};
